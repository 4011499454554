// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-certifications-jsx": () => import("./../../../src/pages/certifications.jsx" /* webpackChunkName: "component---src-pages-certifications-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-diplomas-jsx": () => import("./../../../src/pages/diplomas.jsx" /* webpackChunkName: "component---src-pages-diplomas-jsx" */),
  "component---src-pages-featured-jsx": () => import("./../../../src/pages/featured.jsx" /* webpackChunkName: "component---src-pages-featured-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-portfolio-jsx": () => import("./../../../src/pages/portfolio.jsx" /* webpackChunkName: "component---src-pages-portfolio-jsx" */),
  "component---src-pages-presentation-jsx": () => import("./../../../src/pages/presentation.jsx" /* webpackChunkName: "component---src-pages-presentation-jsx" */),
  "component---src-pages-resume-jsx": () => import("./../../../src/pages/resume.jsx" /* webpackChunkName: "component---src-pages-resume-jsx" */)
}

